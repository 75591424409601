$black80: rgba(0, 0, 0, 0.8);

#root {
  height: 100vh;
}

.custom-scroller {
  * {
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.5);
  }

  ::-webkit-scrollbar {
    width: 9px;
  }

  ::-webkit-scrollbar:horizontal {
    height: 9px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
    // -webkit-border-radius: 4px;
    // border-radius: 4px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 4px;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.5);
  }

  ::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(0, 0, 0, 0.5);
  }
}

.Toastify__toast-container {
  color: $black80 !important;
  line-height: 1.43;
  width: unset !important;
  max-width: 1000px;
}

.Toastify__toast-container--bottom-left {
  left: 100px !important;
}

.Toastify__toast-container--top-right {
  right: 100px !important;
}

.Toastify__toast {
  min-height: unset !important;
  border-radius: 4px !important;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2) !important;
  font-family: "Source Sans Pro", sans-serif;
  color: black;
  padding: 14px 16px !important;
}

.Toastify__toast--error {
  background-color: #ffe7e1 !important;
}

.Toastify__toast--success {
  background-color: #e1fbeb !important;
}

/*
  Modal animation
*/

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Content {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.ReactModal__Content--after-open {
  opacity: 1;
}

.ReactModal__Content--before-close {
  opacity: 0;
}

body {
  font-family: Source Sans Pro, sans-serif;
  overflow: hidden;
}

input {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: black;
}
button {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  // font-weight: 400;
  // letter-spacing: 0.1em;
}

textarea {
  font-family: "Source Sans Pro", sans-serif;
  color: black;
  font-size: 14px;
}

.meraki-form label {
  font-size: 12px !important;
}

label.container {
  font-size: 14px !important;
}

.button {
  background-color: #188ead;
  padding: 5px 10px 5px 10px;
  color: white;
  border: 0px;
  box-shadow: none;
  border-radius: 4px;
  margin: 5px;
  text-transform: uppercase;
  font-size: 14px;
}

p {
  font-size: 14px;
  font-weight: normal;
}

.header-name {
  display: inline-block;
  margin: 16px;
  vertical-align: middle;
  color: black;
}

.profile-name {
  .name {
    display: inline-block;
    vertical-align: middle;
    margin-left: 24px;
  }
}

// EmployeeProfile

// for Confirmation-modal
.scroll-off {
  overflow: hidden !important;
}

select {
  font-family: "Source Sans Pro", sans-serif;
  color: black;
}

// Table changing everything related to table. Cannot handle these is component Library
.merakiTable {
  // border-bottom: unset !important;
  // border-spacing: 0px 1px !important;
  // border-bottom: unset !important;
  border-radius: 4px;
  // overflow: hidden;

  th {
    // white-space: nowrap;
    // padding: 14px 8px;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 600;
    letter-spacing: normal;
    color: #444;
    line-height: 1;
    border-bottom: 0px !important ; //1px solid rgb(221, 221, 221);
    padding: 14px 8px 12px;
    font-size: 12px;
    background-color: #efefef; //my change
    border-bottom: 0px !important;
    text-transform: capitalize;
    vertical-align: top;
  }
  .sticky-header-data,
  .sticky-header-column {
    background-color: #efefef !important;
  }

  td {
    padding: 12px 8px;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #666677;
    line-height: 1;
    vertical-align: baseline;
    text-transform: capitalize;
    // font-size: 12px;

    input::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      text-align: left;
    }
    input::-moz-placeholder {
      /* Firefox 19+ */
      text-align: left;
    }
    input:-ms-input-placeholder {
      /* IE 10+ */
      text-align: left;
    }
    input:-moz-placeholder {
      /* Firefox 18- */
      text-align: left;
    }
  }

  td.number {
    text-align: right;
    font-size: 14px;
    white-space: nowrap;
  }

  // If table td contains checkbox
  th label.container {
    height: 16px;
  }
  td label.container {
    height: 16px;
    input[type="checkbox"],
    span.checkmark {
      top: 5px;
    }
    span.checkmark {
      top: 4px;
    }
  }

  td button.toollink {
    font-size: 12px;
  }
  th.number {
    text-align: right;
  }
  th div {
    line-height: 1 !important;
  }

  tbody tr:first-child td {
    border-top: 1px solid rgb(221, 221, 221);
  }

  .icon-chevron-down {
    background-image: url(../assets/icons/ic-sorting-down.svg);
    // width: 14px;
    // height: 14px;
    // background-size: contain;
    margin: 0px 4px;
    width: 12px;
    height: 12px;
    background-size: 16px;
  }

  tr:hover td {
    background: rgba(255, 204, 0, 0.1);
  }
}

.proposalGroup {
  td {
    vertical-align: top;
  }
}

// .small-table {
// .merakiTable {
//   border-spacing: 0px 1px;
//   border-bottom: unset;

//   th {
//     border-bottom: 1px solid rgb(221, 221, 221);
//     padding: 14px 8px 12px;
//   }

//   tbody tr:first-child td {
//     border-top: 1px solid rgb(221, 221, 221);
//   }

//   th,
//   td {
//     font-size: 12px;
//   }

//   .icon-chevron-down {
//     width: 10px;
//     height: 12px;
//   }
// }
// }

// Bubble Box (move this to component)
.bubble {
  position: relative;

  padding: 12px;
  background: #ffffff;
  margin-bottom: 24px;
  border: #dddddd solid 1px;
}

.bubble:after {
  content: "";
  position: absolute;
  border-style: solid;
  border-width: 0 11px 15px;
  border-color: #ffffff transparent;
  display: block;
  width: 0;
  z-index: 1;
  top: -15px;
  left: 296px;
}

.bubble:before {
  content: "";
  position: absolute;
  border-style: solid;
  border-width: 0 11px 15px;
  border-color: #c1bfbf transparent;
  display: block;
  width: 0;
  z-index: 0;
  top: -16px;
  left: 296px;
}

// Bubble Box (move this to component)
.rmg-bubble {
  position: relative;
  padding: 12px;
  background: #ffffff;
  margin-bottom: 24px;
  border: #dddddd solid 1px;
}

.rmg-bubble:after {
  content: "";
  position: absolute;
  border-style: solid;
  border-width: 0 11px 15px;
  border-color: #ffffff transparent;
  display: block;
  width: 0;
  z-index: 1;
  top: -15px;
  right: 198px;
}

.rmg-bubble:before {
  content: "";
  position: absolute;
  border-style: solid;
  border-width: 0 11px 15px;
  border-color: #c1bfbf transparent;
  display: block;
  width: 0;
  z-index: 0;
  top: -16px;
  right: 198px;
}

.rmg-pool-bubble {
  position: relative;
  padding: 12px;
  background: #ffffff;
  margin-bottom: 24px;
  border: #dddddd solid 1px;
}

.rmg-pool-bubble:after {
  content: "";
  position: absolute;
  border-style: solid;
  border-width: 0 11px 15px;
  border-color: #ffffff transparent;
  display: block;
  width: 0;
  z-index: 1;
  top: -15px;
  right: 297px;
}

.rmg-pool-bubble:before {
  content: "";
  position: absolute;
  border-style: solid;
  border-width: 0 11px 15px;
  border-color: #c1bfbf transparent;
  display: block;
  width: 0;
  z-index: 0;
  top: -16px;
  right: 297px;
}

// Bubble Box (move this to component)
.hiring-request-bubble {
  position: relative;

  padding: 12px;
  background: #ffffff;
  margin-bottom: 24px;
  border: #dddddd solid 1px;
}

.hiring-request-bubble:after {
  content: "";
  position: absolute;
  border-style: solid;
  border-width: 0 11px 15px;
  border-color: #ffffff transparent;
  display: block;
  width: 0;
  z-index: 1;
  top: -15px;
  right: 2px;
}

.hiring-request-bubble:before {
  content: "";
  position: absolute;
  border-style: solid;
  border-width: 0 11px 15px;
  border-color: #c1bfbf transparent;
  display: block;
  width: 0;
  z-index: 0;
  top: -16px;
  right: 2px;
}

.hiring-request-rm-bubble {
  position: relative;

  padding: 12px;
  background: #ffffff;
  margin-bottom: 24px;
  border: #dddddd solid 1px;
}

.hiring-request-rm-bubble:after {
  content: "";
  position: absolute;
  border-style: solid;
  border-width: 0 11px 15px;
  border-color: #ffffff transparent;
  display: block;
  width: 0;
  z-index: 1;
  top: -15px;
  right: 192px;
}

.hiring-request-rm-bubble:before {
  content: "";
  position: absolute;
  border-style: solid;
  border-width: 0 11px 15px;
  border-color: #c1bfbf transparent;
  display: block;
  width: 0;
  z-index: 0;
  top: -16px;
  right: 192px;
}

.message-bubble {
  position: relative;

  padding: 12px;
  background: #ffffff;
  margin-bottom: 24px;
  border: #dddddd solid 1px;
}

.message-bubble:after {
  content: "";
  position: absolute;
  border-style: solid;
  border-width: 0 11px 15px;
  border-color: #ffffff transparent;
  display: block;
  width: 0;
  z-index: 1;
  top: -15px;
  left: 1px;
}

.message-bubble:before {
  content: "";
  position: absolute;
  border-style: solid;
  border-width: 0 11px 15px;
  border-color: #c1bfbf transparent;
  display: block;
  width: 0;
  z-index: 0;
  top: -16px;
  left: 1px;
}

#meraki-toolbox {
  // #layout_2 {
  //   grid-template-columns: 50% 50%;
  // }
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

.pre-wrap {
  white-space: pre-wrap;
}

.display-text-wrapper {
  margin-top: 16px;
  padding: 8px 8px 16px 8px;
  white-space: pre-wrap;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  border: 1px solid rgb(221, 221, 221);
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

//Funnel
.hro-funnel .svg-funnel-js:not(.svg-funnel-js--vertical) {
  padding-top: 16px !important;
  padding-bottom: 0px !important;
}

.listing-funnel .svg-funnel-js:not(.svg-funnel-js--vertical) {
  padding-top: 16px !important;
  padding-bottom: 0px !important;
}

.hro-stats-funnel
  .svg-funnel-js:not(.svg-funnel-js--vertical)
  .svg-funnel-js__label {
  padding-left: 8px;
}

.hro-stats-funnel
  .svg-funnel-js
  .svg-funnel-js__labels
  .label__segment-percentages {
  padding: 24px 24px 24px 11px !important;
}

.hro-stats-funnel
  .svg-funnel-js
  .svg-funnel-js__labels
  .label__segment-percentages:hover {
  padding: 24px 24px 24px 11px !important;
}

// app level fixes for input, selects, text-area and markdown
input.meraki-input,
textarea {
  border: 1px solid rgba(221, 221, 221, 1);
  padding: 6px 8px 6px 8px;

  &:disabled {
    border: 1px solid rgba(221, 221, 221, 1);
  }
}

textarea.meraki-textarea-element {
  border: 1px solid rgba(221, 221, 221, 1);
  padding: 6px 8px 6px 8px !important;

  &:disabled {
    border: 1px solid rgba(221, 221, 221, 1);
  }
}

.meraki-datepicker {
  .react-datepicker-wrapper {
    input {
      border: 1px solid rgba(221, 221, 221, 1);
      // &:disabled {
      //   border: 1px solid rgba(221, 221, 221, 1);
      // }
    }
  }
}

.react-datepicker-popper {
  z-index: 100000000 !important;

  .react-datepicker__day--disabled.react-datepicker__day--highlighted:not(.react-datepicker__day--selected) {
    color: rgba(153, 153, 153, 1) !important;
    background-color: #ffffff;
    border-radius: 0;
    border-bottom: 2px solid rgba(24, 142, 173, 1);
  }

  .react-datepicker__day--disabled {
    color: rgba(153, 153, 153, 1) !important;
  }
  .react-datepicker__day--keyboard-selected:not(.react-datepicker__day--in-range) {
    color: unset;
    background-color: unset;
  }
}

.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
  background-color: #1d5d90 !important;
}

#meraki-react-select.react-select-container .react-select__control {
  border: 1px solid rgba(221, 221, 221, 1);
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: black;
  // color: #444444;
  &:hover {
    border-color: rgba(24, 142, 173, 1);
    cursor: pointer;
  }
}

#meraki-react-select.react-select-container .react-select__control--is-focused {
  border-color: rgba(24, 142, 173, 1);
}

#meraki-react-select-autocomplete.react-select-container
  .react-select__control {
  border: 1px solid rgba(221, 221, 221, 1);
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #444444;
}

.react-select__placeholder {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

#meraki-react-select.react-select-container {
  text-transform: capitalize;
}

#meraki-react-select.react-select-container .react-select__single-value {
  font-size: 14px !important;
}

.react-mde {
  border: 1px solid rgba(221, 221, 221, 1);
  border-radius: 4px;

  textarea.mde-text {
    padding: 6px 8px 6px 8px !important;
  }
}

.margin-bottom-16 {
  display: block;
  margin-bottom: 16px;
}

.meraki-AddAttachment {
  &:not(:hover) {
    border: 1px solid rgba(221, 221, 221, 1) !important;
  }
  &:hover {
    border-color: rgba(24, 142, 173, 1);
    cursor: pointer;
  }
}

.icon-hover:hover {
  filter: invert(1);
}

.meraki-form {
  // min-height: 400px;
  h3 {
    border-bottom: 1px solid rgb(221, 221, 221);
    font-family: "Source Sans Pro", sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 20px;
    letter-spacing: normal;
    color: rgba(68, 68, 68, 1);
    display: block !important;
    text-transform: none;
  }
  h3.meraki-form-title {
    margin-bottom: 24px;
    margin-top: 24px;
  }
}

.lowercase {
  text-transform: lowercase;
}

.danger {
  color: red !important;
}

.currency-symbol {
  color: #bbbbbb;
  vertical-align: middle;
  font-weight: normal;
  margin-right: 2px;
}

// PopOver Component
// #openedComponent {
//   .arrow_box {
//     overflow: scroll;
//   }
// }

// pop-over-v2
.pop-over-v2 {
  position: absolute;
  // color: tokens.colors.secondary.lightest;
  // color: rgb(255, 255, 255);
  // background-color: rgb(68, 68, 68);
  z-index: 9999;
  // padding: 8px 12px;
  // box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(187, 187, 187, 1);
  border-radius: 4px;
  box-shadow: 1px 4px 8px 0px #999999;
  padding: 8px;
  background-color: rgba(255, 255, 255, 1);
  &::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    width: 0;
    height: 0;
    border-left: solid 4px transparent;
    border-right: solid 4px transparent;
  }
  &.bottom-right::after {
    bottom: 100%;
    margin: 0 12px;
    // borderBottom: solid 4px rgba(0,0,0,0.0);
    // border-bottom: 4px solid rgb(68, 68, 68);
  }
  &.bottom-left::after {
    bottom: 100%;
    margin: 0 calc(100% - 20px);
    // borderBottom: solid 4px rgba(0,0,0,0.0);
    // border-bottom: 4px solid rgb(68, 68, 68);
  }
  &.top-left::after {
    top: 100%;
    margin: 0 calc(100% - 20px);
    // borderTop: solid 4px rgba(0,0,0,0.0);
    // border-top: 4px solid rgb(68, 68, 68);
  }
  &.top-right::after {
    top: 100%;
    margin: 0 12px;
    // borderTop: solid 4px rgba(0,0,0,0.0);
    // border-top: 4px solid rgb(68, 68, 68);
  }
}

reach-portal div {
  z-index: 9999999;
}
