// Typography

h1 {
  font-size: 24px;

  line-height: 32px;
}

h2 {
  font-size: 22px;

  line-height: 28px;
}

h3 {
  font-size: 16px;

  line-height: 28px;
}

h4 {
  font-size: 14px;

  line-height: 18px;
}

p {
  font-size: 12px;

  line-height: 16px;
}

.subtext {
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
}

.header1 {
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  color: #212134;
}

.header2 {
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  color: #32324d;
}

.header3 {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  color: #32324d;
}

.header4 {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #32324d;
}

.subtitle {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #32324d;
}

.subtitle2 {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #8e8ea9;
}

.body {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #32324d;
}

.body2 {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #8e8ea9;
}

.body-highlight {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #32324d;
}

.button-text {
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  color: #32324d;
}

.small-text {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #32324d;
}

.small-text2 {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #8e8ea9;
}

.small-button-text {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  color: #32324d;
}

.table-label {
  font-size: 11px;
  font-weight: 600;
  line-height: 16px;
  color: #32324d;
  text-transform: uppercase;
}

.table-label2 {
  font-size: 11px;
  font-weight: 600;
  line-height: 16px;
  color: #666687;
  text-transform: uppercase;
}
