@import "app";
@import "icons";
@import "typography";
@import "tempIntrojs";
@import "helper";
@import "fonts";

* {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.logo {
  padding: 0;
  width: 100px;
  height: 50px;
  background-size: contain;
  background-repeat: no-repeat;
}
