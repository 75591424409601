.icon {
  background-position: center;
  background-repeat: no-repeat;
  display: inline-block;
  vertical-align: top;
}

.icon-large {
  @extend .icon;
  width: 48px;
  height: 48px;
  background-size: 48px;
}

.icon-medium {
  @extend .icon;
  width: 24px;
  height: 24px;
  // background-size: 48px;
}

.icon-small {
  @extend .icon;
  width: 12px;
  height: 12px;
  background-size: 12px;
}

.icon-14 {
  @extend .icon;
  width: 14px;
  height: 14px;
  background-size: 14px;
}

.icon-4 {
  @extend .icon;
  width: 4px;
  height: 4px;
}

.icon-8 {
  @extend .icon;
  width: 8px;
  height: 8px;
}

.icon-12 {
  @extend .icon;
  width: 12px;
  height: 12px;
  background-size: 12px;
}

.icon-16 {
  @extend .icon;
  width: 16px;
  height: 16px;
  background-size: 16px;
}

.icon-20 {
  @extend .icon;
  width: 20px;
  height: 20px;
  background-size: 20px;
}

.icon-24 {
  @extend .icon;
  width: 24px;
  height: 24px;
  background-size: 24px;
}

.icon-26 {
  @extend .icon;
  width: 26px;
  height: 26px;
  background-size: 26px;
}

.icon-28 {
  @extend .icon;
  width: 26px;
  height: 26px;
  background-size: 26px;
}

.icon-30 {
  @extend .icon;
  width: 30px;
  height: 30px;
  background-size: 30px;
}

.icon-32 {
  @extend .icon;
  width: 32px;
  height: 32px;
  background-size: 32px;
}

.icon-36 {
  @extend .icon;
  width: 36px;
  height: 36px;
  background-size: 36px;
}

.icon-48 {
  @extend .icon;
  width: 48px;
  height: 48px;
  background-size: 48px;
}

.icon-50 {
  @extend .icon;
  width: 50px;
  height: 50px;
  background-size: 50px;
}

.icon-72 {
  @extend .icon;
  width: 72px;
  height: 72px;
  background-size: 72px;
}

.icon-ok {
  @extend .icon;
  background-image: url(../assets/icons/ic-ok.svg);
}

.icon-stop {
  @extend .icon;
  background-image: url(../assets/icons/stop.svg);
}

.icon-chevron-down {
  @extend .icon;
  background-image: url(../assets/icons/ic-down-arrow.svg);
}

.icon-delete {
  @extend.icon;
  background-image: url(../assets/icons/ic-delete.svg);
  background-size: auto;
}

.icon-delete-secondary {
  @extend.icon;
  background-image: url(../assets/icons/ic-delete-secondary.svg);
  background-size: auto;
}

.icon-edit {
  @extend.icon;
  background-image: url(../assets/icons/ic-edit.svg);
}

.icon-edit-white {
  @extend .icon;
  background-image: url(../assets/icons/ic-edit-white.svg);
}

.icon-confirmation {
  @extend.icon;
  background-image: url(../assets/icons/ic-confirmation.svg);
}

.icon-confirmation-white {
  @extend.icon;
  background-image: url(../assets/icons/ic-confirmation-white.svg);
}

.icon-back-arrow {
  @extend.icon;
  background-image: url(../assets/icons/ic-back-arrow.svg);
}

.icon-calendar-arrow-back {
  @extend .icon;
  background-image: url(../assets/icons/ic-calendar-arrow-back.svg);
}

.icon-calendar-arrow-forward {
  @extend .icon;
  background-image: url(../assets/icons/ic-calendar-arrow-forward.svg.svg);
}

.icon-help {
  @extend.icon;
  background-image: url(../assets/icons/ic-help-grey.svg);
}

.icon-loading {
  @extend .icon;
  background-image: url(../assets/icons/ic-loading.svg);
}

.icon-page-loader {
  @extend .icon;
  background-image: url(../assets/loader.svg);
}

.icon-calendar {
  @extend .icon;
  background-image: url(../assets/icons/ic-calender-grey.svg);
}

.icon-calendar-2 {
  @extend .icon;
  background-image: url(../assets/icons/ic-calendar-2.svg);
}

.icon-cross-with-round {
  @extend .icon;
  background-image: url(../assets/icons/ic-cross-with-round.svg);
}

.icon-cross-in-circle {
  @extend .icon;
  background-image: url(../assets/icons/ic-cross-in-circle.svg);
}

.icon-cross-with-round-blue {
  @extend .icon;
  background-image: url(../assets/icons/ic-cross-with-round-blue.svg);
}

.icon-close {
  @extend .icon;
  background-image: url(../assets/icons/ic-close.svg);
}

.icon-round-close {
  @extend .icon;
  background-image: url(../assets/icons/ic-fail.svg);
}

.icon-warning-close {
  @extend .icon;
  background-image: url(../assets/icons/fail.svg);
}

.icon-filter {
  @extend .icon;
  background-image: url(../assets/icons/icon-filter.svg);
}

.icon-filter-primary {
  @extend .icon;
  background-image: url(../assets/icons/icon-filter-primary.svg);
}

.icon-filter-active {
  @extend .icon;
  background-image: url(../assets/icons/icon-filter-active.svg);
}

.icon-tune {
  @extend .icon;
  background-image: url(../assets/icons/ic-tune.svg);
}

.icon-email {
  @extend .icon;
  background-image: url(../assets/icons/ic-email.svg);
}

.icon-phone {
  @extend .icon;
  background-image: url(../assets/icons/ic-telephone.svg);
}

.icon-profile {
  @extend .icon;
  background-image: url(../assets/icons/ic-user-profile.svg);
}

.icon-hollow-circle {
  @extend .icon;
  background-image: url(../assets/icons/ic-hollow-circle.svg);
}

.icon-ghanta {
  @extend .icon;
  background-image: url(../assets/icons/ic-ghanta.svg);
}

.icon-ghanta-alert {
  @extend .icon;
  background-image: url(../assets/icons/ic-ghanta-alert.svg);
}

.icon-download {
  @extend .icon;
  background-image: url(../assets/icons/ic-download.svg);
}

.icon-download-white {
  @extend .icon;
  background-image: url(../assets/icons/ic-download-white.svg);
}

.icon-upload {
  @extend .icon;
  background-image: url(../assets/icons/ic-upload.svg);
}

.icon-upload-white {
  @extend .icon;
  background-image: url(../assets/icons/upload.svg);
}

.icon-add {
  @extend .icon;
  background-image: url(../assets/icons/ic-add.svg);
}

.icon-add-dark {
  @extend .icon;
  background-image: url(../assets/icons/ic-add-dark.svg);
}

.icon-blue-add {
  @extend .icon;
  background-image: url(../assets/icons/ic-blue-add.svg);
}

.icon-dash {
  @extend .icon;
  background-image: url(../assets/icons/ic-dash.svg);
}

.icon-dash-dark {
  @extend .icon;
  background-image: url(../assets/icons/ic-dash-dark.svg);
}
.icon-front {
  @extend .icon;
  background-image: url(../assets/icons/ic-blue-dropdown.svg);
  transform: rotate(270deg);
}

.icon-red-close {
  @extend .icon;
  background-image: url(../assets/icons/ic-red-close.svg);
}

.icon-blue-check {
  @extend .icon;
  background-image: url(../assets/icons/ic-blue-check.svg);
}

.icon-success-check {
  @extend .icon;
  background-image: url(../assets/icons/ic-check-sucess.svg);
}

.icon-search {
  @extend .icon;
  background-image: url(../assets/icons/ic-search.svg);
}

.icon-high-priority {
  @extend .icon;
  background-image: url(../assets/icons/ic-high-priority.svg);
}

.icon-medium-priority {
  @extend .icon;
  background-image: url(../assets/icons/ic-medium-priority.svg);
}

.icon-error {
  @extend .icon;
  background-image: url(../assets/icons/ic-error.svg);
}

.icon-info-grey {
  @extend .icon;
  background-image: url(../assets/icons/ic-info-grey.svg);
}

.icon-info {
  @extend .icon;
  background-image: url(../assets/icons/ic-info.svg);
}

.icon-info-v2 {
  @extend .icon;
  background-image: url(../assets/icons/ic-info-v2.svg);
}

.icon-blue-more {
  @extend .icon;
  background-image: url(../assets/icons/ic-blue-more.svg);
  transform: rotate(90deg);
}

.icon-filled-star {
  @extend .icon;
  background-image: url(../assets/icons/ic-filled-star.svg);
}

.icon-star {
  @extend .icon;
  background-image: url(../assets/icons/ic-star.svg);
}

.icon-bullet-warning {
  @extend .icon;
  background-image: url(../assets/icons/ic-bullet-warning.svg);
}

.icon-bullet-success {
  @extend .icon;
  background-image: url(../assets/icons/ic-bullet-success.svg);
}

.icon-bullet-danger {
  @extend .icon;
  background-image: url(../assets/icons/ic-bullet-danger.svg);
}

.icon-bullet-gray {
  @extend .icon;
  background-image: url(../assets/icons/ic-bullet-gray.svg);
}

.icon-attachment {
  @extend .icon;
  background-image: url(../assets/icons/ic-attachment.svg);
}

.icon-check-white {
  @extend .icon;
  background-image: url(../assets/icons/ic-check-white.svg);
}

.icon-note {
  @extend .icon;
  background-image: url(../assets/icons/ic-note.svg);
}

.icon-message {
  @extend .icon;
  background-image: url(../assets/icons/ic-messages.svg);
}

.icon-message-2 {
  @extend .icon;
  background-image: url(../assets/icons/ic-message-2.svg);
}

.icon-user {
  @extend .icon;
  background-image: url(../assets/icons/ic-user.svg);
}

.icon-user-secondary {
  @extend .icon;
  background-image: url(../assets/icons/ic-user-secondary.svg);
}

.icon-user-2 {
  @extend .icon;
  background-image: url(../assets/icons/ic-user-2.svg);
}

.icon-user-2-secondary {
  @extend .icon;
  background-image: url(../assets/icons/ic-user-2-secondary.svg);
}

.icon-users {
  @extend .icon;
  background-image: url(../assets/icons/ic-users.svg);
}

.icon-users-secondary {
  @extend .icon;
  background-image: url(../assets/icons/ic-users-secondary.svg);
}

.icon-user-secondary-blue {
  @extend .icon;
  background-image: url(../assets/icons/ic-user-secondary-blue.svg);
}

.icon-calender-grey {
  @extend .icon;
  background-image: url(../assets/icons/ic-calender-grey.svg);
}

.icon-open-the-tool {
  @extend .icon;
  width: 5px;
  height: 10px;
  object-fit: contain;
  background-image: url(../assets/icons/ic-open-the-tool.svg);
}

.icon-dropdown {
  @extend .icon;
  width: 14px;
  height: 7px;
  object-fit: contain;
  background-image: url(../assets/icons/ic-dropdown.svg);
}

.icon-check-in-circle {
  @extend .icon;
  background-image: url(../assets/icons/check-in-circle.svg);
}

.icon-time {
  @extend .icon;
  background-image: url(../assets/icons/ic-time.svg);
}

.icon-time-secondary {
  @extend .icon;
  background-image: url(../assets/icons/ic-time-secondary.svg);
  background-size: 23px;
}

.icon-time-secondary-blue {
  @extend .icon;
  background-image: url(../assets/icons/ic-time-secondary-blue.svg);
}

.icon-details {
  @extend .icon;
  background-image: url(../assets/icons/ic-details.svg);
}
.icon-details-old {
  @extend .icon;
  background-image: url(../assets/icons/ic-details-old.svg);
}

.icon-details-secondary {
  @extend .icon;
  background-image: url(../assets/icons/ic-details-secondary.svg);
}

.icon-details-secondary-blue {
  @extend .icon;
  background-image: url(../assets/icons/ic-details-secondary-blue.svg);
}

.icon-parent-connection {
  @extend .icon;
  background-image: url(../assets/icons/ic-parent-connection.svg);
}

.under-development {
  @extend .icon;
  width: 100%;
  border-radius: 4px;
  background-image: url(../assets/ttiud.png);
}

.icon-blue-flag {
  @extend .icon;
  background-image: url(../assets/icons/ic-blue-flag.svg);
  background-size: auto;
}

.icon-red-flag {
  @extend .icon;
  background-image: url(../assets/icons/ic-red-flag.svg);
  background-size: auto;
}

.icon-bullet-grey {
  @extend .icon;
  background-image: url(../assets/icons/ic-bullet-grey.svg);
}

.icon-bullet-primary {
  @extend .icon;
  background-image: url(../assets/icons/ic-bullet-primary.svg);
}

.icon-bullet-red {
  @extend .icon;
  background-image: url(../assets/icons/ic-bullet-red.svg);
}

.icon-empty-circle {
  @extend .icon;
  background-image: url(../assets/icons/empty.svg);
}

.icon-more {
  background-image: url(../assets/icons/ic-more.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.icon-show-more {
  background-image: url(../assets/icons/ic-show-more.svg);
}

.icon-menu {
  background-image: url(../assets/icons/ic-menu.svg);
}

.icon-setting {
  background-image: url(../assets/icons/ic-setting.svg);
}

.icon-setting-secondary {
  background-image: url(../assets/icons/ic-setting-secondary.svg);
}

.icon-setting-secondary-blue {
  background-image: url(../assets/icons/ic-setting-secondary-blue.svg);
}

.icon-expense {
  background-image: url(../assets/icons/expense-app.svg);
}

.icon-expense-secondary {
  background-image: url(../assets/icons/expense-app-secondary.svg);
}

.icon-expense-secondary-blue {
  background-image: url(../assets/icons/ic-expense-secondary-blue.svg);
}

.icon-check-big {
  background-image: url(../assets/icons/check-big.svg);
}

.icon-unlink {
  background-image: url(../assets/icons/unlink-red.svg);
}

.icon-unlink-white {
  background-image: url(../assets/icons/unlink-white.svg);
}

.icon-orange-flag {
  @extend .icon;
  background-image: url(../assets/icons/ic-orange-flag.svg);
  background-size: auto;
}

.icon-black-flag {
  @extend .icon;
  background-image: url(../assets/icons/ic-black-flag.svg);
  background-size: auto;
}

.icon-primary-external-link {
  @extend .icon;
  background-image: url(../assets/icons/ic-primary-external-link.svg);
}

.icon-minimize {
  @extend .icon;
  background-image: url(../assets/icons/icons-expand-contract.svg);
}

.icon-maximize {
  @extend .icon;
  background-image: url(../assets/icons/icons-expand-contract.svg);
}

.icon-admin {
  @extend .icon;
  background-image: url(../assets/icons/ic-admin.svg);
}

.icon-docs {
  @extend .icon;
  background-image: url(../assets/icons/ic-docs.svg);
}

.icon-chevron-prev {
  @extend .icon;
  background-image: url(../assets/icons/chevron-left-solid.svg);
  outline: none;
  border: 1px solid;
  background-color: rgba(24, 142, 173, 1);
  border-color: rgba(24, 142, 173, 1);
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-left-radius: 0.25em;
  border-bottom-left-radius: 0.25em;
  height: auto;
  width: 40px;
}

.icon-chevron-next {
  @extend .icon;
  background-image: url(../assets/icons/chevron-right-solid.svg);
  background-color: rgba(24, 142, 173, 1);
  border-color: rgba(24, 142, 173, 1);
  border: 1px solid;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 0.25em;
  border-bottom-right-radius: 0.25em;
  outline: none;
  height: auto;
  width: 40px;
}

.icon-last-edited {
  @extend .icon;
  background-image: url(../assets/icons/ic-last-edited.svg);
}

.icon-call {
  @extend .icon;
  background-image: url(../assets/icons/ic-call.svg);
}

.icon-history {
  @extend .icon;
  background-image: url(../assets/icons/ic-history.svg);
}

.icon-prev-solid {
  @extend .icon;
  background-image: url(../assets/icons/prev-solid.svg);
}

.icon-next-solid {
  @extend .icon;
  background-image: url(../assets/icons/next-solid.svg);
}

.icon-docs {
  @extend .icon;
  background-image: url(../assets/icons/ic-docs.svg);
}

.icon-resource-default {
  @extend .icon;
  background-image: url(../assets/icons/ic-resource-default.svg);
}

.icon-resource-success {
  @extend .icon;
  background-image: url(../assets/icons/ic-resource-success.svg);
}

.icon-resource-danger {
  @extend .icon;
  background-image: url(../assets/icons/ic-resource-danger.svg);
}

.icon-resource-warning {
  @extend .icon;
  background-image: url(../assets/icons/ic-resource-warning.svg);
}

.icon-resource-secondary {
  @extend .icon;
  background-image: url(../assets/icons/ic-resource-secondary.svg);
}

.icon-address-book {
  @extend .icon;
  background-image: url(../assets/icons/ic-address-book.svg);
}

.icon-success {
  @extend .icon;
  background-image: url(../assets/icons/ic-success.svg);
}
.icon-double-right-arrows {
  @extend .icon;
  background-image: url(../assets/icons/double-right-arrows-angles.svg);
}
.icon-search-employee {
  @extend .icon;
  background-image: url(../assets/icons/icon-search-mug.svg);
}
.icon-search-opportunity {
  @extend .icon;
  background-image: url(../assets/icons/icon-search-opportunity.svg);
}
.icon-search-project {
  @extend .icon;
  background-image: url(../assets/icons/icon-search-project.svg);
}
.icon-chevron-dark-next {
  @extend .icon;
  background-image: url(../assets/icons/chevron-right-dark.svg);
}
.icon-chevron-dark-prev {
  @extend .icon;
  background-image: url(../assets/icons/chevron-left-dark.svg);
}

.icon-grid {
  @extend .icon;
  background-image: url(../assets/icons/ic-grid.svg);
}

.icon-list {
  @extend .icon;
  background-image: url(../assets/icons/ic-list.svg);
}

.icon-pyramid-inactive {
  @extend .icon;
  background-image: url(../assets/icons/ic-pyramid-inactive.svg);
}

.icon-pyramid-active {
  @extend .icon;
  background-image: url(../assets/icons/ic-pyramid-active.svg);
}

.icon-table-inactive {
  @extend .icon;
  background-image: url(../assets/icons/ic-table-inactive.svg);
}

.icon-table-active {
  @extend .icon;
  background-image: url(../assets/icons/ic-table-active.svg);
}

.icon-edit-secondary {
  @extend .icon;
  background-image: url(../assets/icons/ic-edit-secondary.svg);
}

.icon-recurring {
  @extend .icon;
  background-image: url(../assets/icons/recurring.svg);
}

.icon-warning {
  @extend .icon;
  background-image: url(../assets/icons/ic-warning.svg);
}

.icon-warning-v2 {
  @extend .icon;
  background-image: url(../assets/icons/ic-warning-v2.svg);
}

.icon-big-info {
  @extend .icon;
  background-image: url(../assets/icons/ic-big-info.svg);
}

.icon-sort {
  @extend .icon;
  background-image: url(../assets/icons/ic-sort.svg);
}

.icon-open-eye {
  @extend .icon;
  background-image: url(../assets/icons/open-eye.svg);
}

.icon-closed-eye {
  @extend .icon;
  background-image: url(../assets/icons/closed-eye.svg);
}

.icon-table-sort-active {
  @extend .icon;
  background-image: url(../assets/icons/ic-table-sort-active.svg);
}

.icon-table-sort {
  @extend .icon;
  background-image: url(../assets/icons/ic-table-sort.svg);
}

.icon-down-right {
  @extend .icon;
  background-image: url(../assets/icons/ic-down-right.svg);
}

.icon-arrow-right {
  @extend .icon;
  background-image: url(../assets/icons/arrow-right.svg);
}

.icon-remove {
  @extend .icon;
  background-image: url(../assets/icons/ic-remove.svg);
}
.icon-back {
  @extend .icon;
  background-image: url(../assets/icons/ic-back.svg);
}

.icon-back-new {
  @extend .icon;
  background-image: url(../assets/icons/ic-back-new.svg);
}

.icon-line {
  @extend .icon;
  height: 24px;
  width: 0px;
  border: 1px solid #eaeaef;
  background-size: 24px;
  background-image: url(../assets/icons/ic-line.svg);
}
.icon-resume {
  @extend .icon;
  background-image: url(../assets/icons/ic-resume.svg);
}
.icon-new-download {
  @extend .icon;
  background-image: url(../assets/icons/ic-new-download.svg);
}

.icon-bolt {
  @extend .icon;
  background-image: url(../assets/icons/ic-bolt.svg);
}

.icon-star {
  @extend .icon;
  background-image: url(../assets/icons/ic-star-filled.svg);
}

.icon-star-outline {
  @extend .icon;
  background-image: url(../assets/icons/ic-star-empty.svg);
}

.icon-next-option {
  @extend .icon;
  background-image: url(../assets/icons/ic-next-option.svg);
}

.icon-back {
  @extend .icon;
  background-image: url(../assets/icons/ic-back.svg);
}

.warning-icon {
  @extend .icon;
  background-image: url(../assets/icons/warning_icon.svg);
}

.success-icon {
  @extend .icon;
  background-image: url(../assets/icons/success_icon.svg);
}

.icon-remove {
  @extend .icon;
  background-image: url(../assets/icons/ic-remove.svg);
}

.icon-search-web {
  @extend .icon;
  background-image: url(../assets/icons/ic-search-web.svg);
}

.icon-filter-web {
  @extend .icon;
  background-image: url(../assets/icons/ic-filter-web.svg);
}

.icon-filter-active-web {
  @extend .icon;
  background-image: url(../assets/icons/ic-filter-active-web.svg);
}

.icon-delete-web {
  @extend .icon;
  background-image: url(../assets/icons/ic-delete-web.svg);
}

.warning-icon {
  @extend .icon;
  background-image: url(../assets/icons/warning_icon.svg);
}

.success-icon {
  @extend .icon;
  background-image: url(../assets/icons/success_icon.svg);
}

.email-icon {
  @extend .icon;
  background-image: url(../assets/icons/email-icon.svg);
}

.phone-icon {
  @extend .icon;
  background-image: url(../assets/icons/phone-icon.svg);
}

.person-icon {
  @extend .icon;
  background-image: url(../assets/icons/person-icon.svg);
}
.dropdown-icon {
  @extend .icon;
  background-image: url(../assets/icons/ic-dropdown.svg);
}
.arrow-drop-down {
  @extend .icon;
  background-image: url(../assets/icons/arrow-drop-down.svg);
}

.icon-cross-web {
  @extend .icon;
  background-image: url(../assets/icons/ic-cross-web.svg);
}

.icon-filter-applied-web {
  @extend .icon;
  background-image: url(../assets/icons/ic-filter-applied-web.svg);
}

.icon-filter-applied-active-web {
  @extend .icon;
  background-image: url(../assets/icons/ic-filter-applied-active-web.svg);
}

.copy-icon {
  @extend .icon;
  background-image: url(../assets/icons/copy-content.svg);
}

.icon-briefcase {
  @extend .icon;
  background-image: url(../assets/icons/ic-briefcase.svg);
}

/************************* New Icons **************************/

.icon-lock {
  @extend .icon;
  background-image: url(../assets/icons/ic-lock.svg);
}

.icon-info-danger {
  @extend .icon;
  background-image: url(../assets/icons/ic-info-danger.svg);
}

.icon-info-white {
  @extend .icon;
  background-image: url(../assets/icons/ic-info-white.svg);
}
.icon-todo {
  @extend .icon;
  background-image: url(../assets/icons/notepad.svg);
}

.icon-edit-web {
  @extend .icon;
  background-image: url(../assets/icons/ic-edit-web.svg);
}

.icon-delete-web-v2 {
  @extend .icon;
  background-image: url(../assets/icons/ic-delete-web-v2.svg);
}

.icon-arrow-up-success {
  @extend .icon;
  background-image: url(../assets/icons/ic-arrow-up-success.svg);
}

.icon-arrow-down-danger {
  @extend .icon;
  background-image: url(../assets/icons/ic-arrow-down-danger.svg);
}

.icon-double-up-arrows {
  @extend .icon;
  background-image: url(../assets/icons/chevrons-up.svg);
}

.icon-double-down-arrows {
  @extend .icon;
  background-image: url(../assets/icons/chevrons-down.svg);
}

.icon-info-success {
  @extend .icon;
  background-image: url(../assets/icons/ic-info-success.svg);
}

.icon-info-error {
  @extend .icon;
  background-image: url(../assets/icons/ic-info-error.svg);
}

.icon-info-warning {
  @extend .icon;
  background-image: url(../assets/icons/ic-info-warning.svg);
}

.icon-people-group {
  @extend .icon;
  background-image: url(../assets/icons/people.svg);
}

.icon-line-graph {
  @extend .icon;
  background-image: url(../assets/icons/line-chart.svg);
}

.icon-ungroup {
  @extend .icon;
  background-image: url(../assets/icons/ic-ungroup-regular.svg);
}
.icon-google {
  @extend .icon;
  background-image: url(../assets/icons/ic-google.svg);
}

.icon-usage {
  @extend .icon;
  background-image: url(../assets/icons/usage.svg);
}

.icon-drag-indicator {
  @extend .icon;
  background-image: url(../assets/icons/drag-indicator.svg);
}

.icon-loading-blue {
  @extend .icon;
  background-image: url(../assets/icons/ic-loading-blue.svg);
}

.icon-upload-web {
  @extend .icon;
  background-image: url(../assets/icons/ic-upload-web.svg);
}

.icon-bhonga {
  @extend .icon;
  background-image: url(../assets/icons/ic-campaign.svg);
}

.icon-hierarchy {
  @extend .icon;
  background-image: url(../assets/icons/heirarchy.svg);
}

.icon-table-view {
  @extend .icon;
  background-image: url(../assets/icons/ic-table-view.svg);
}

.icon-redirect {
  @extend .icon;
  background-image: url(../assets/icons/share.svg);
}

.icon-upload-2 {
  @extend .icon;
  background-image: url(../assets/icons/ic-upload-2.svg);
}

.icon-error-danger {
  @extend .icon;
  background-image: url(../assets/icons/ic-error-danger.svg);
}

.icon-not-started {
  @extend .icon;
  background-image: url(../assets/icons/ic-not-started.svg);
}

.icon-processing {
  @extend .icon;
  background-image: url(../assets/icons/ic-processing.svg);
}
